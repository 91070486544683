import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { ResponseResult } from 'src/app/shared/models/response';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment.development';
import { CurrencyModel } from '../models/common-lookups/currency.model';
import { CountryModel } from '../models/common-lookups/country.model';
import { LanguageModel } from '../models/common-lookups/language.model';
import { TimeZoneModel } from '../models/common-lookups/time-zone.model';
import { UserRole } from '../models/common-lookups/user-roles.model';

@Injectable({
  providedIn: 'root'
})
export class CommonLookupService extends BaseService {

  adminAPIUrl = environment.adminResourceUrl;
  constructor(
    private http: HttpClient
  ) {
    super();
  } 
  
  getTimeZoneList(): Observable<ResponseResult<TimeZoneModel[]>> {
    return this.http.get<ResponseResult<TimeZoneModel[]>>(`${this.adminAPIUrl}api/lookups/common/time-zones`, this.httpOptions)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  getCurrencyList(): Observable<ResponseResult<CurrencyModel[]>> {
    return this.http.get<ResponseResult<CurrencyModel[]>>(`${this.adminAPIUrl}api/lookups/common/currencies`, this.httpOptions)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  getCountryList(): Observable<ResponseResult<CountryModel[]>> {
    return this.http.get<ResponseResult<CountryModel[]>>(`${this.adminAPIUrl}api/lookups/common/countries`, this.httpOptions)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  getLanguageList(): Observable<ResponseResult<LanguageModel[]>> {
    return this.http.get<ResponseResult<LanguageModel[]>>(`${this.adminAPIUrl}api/lookups/common/languages`, this.httpOptions)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  getUserRoleList(): Observable<ResponseResult<UserRole[]>> {
    return this.http.get<ResponseResult<UserRole[]>>(`${this.adminAPIUrl}api/lookups/common/user-roles`, this.httpOptions)
    .pipe(map(resp => resp), catchError(this.handleServerError));
  }
}
